<template>
  <div class="screen">
    <ul class="screen-list">
      <li class="screen-item" @click="showDrawer">商品分类</li>
      <li class="screen-item" @click="showDrawer">商品性质</li>
      <li class="screen-item" @click="showDrawer">商品尺寸</li>
      <li class="screen-item" @click="showDrawer">更多筛选</li>
    </ul>

    <div class="sort-fun db-mt24">
      <SortBtn class="db-mr20" :title="'销量'" :i-sort="true" @isClick="isSortFindFun" @sorType="SortTypeFun" />
      <SortBtn class="db-mr20" :title="'价格'" :i-sort="true" @isClick="isSortFindFun" @sorType="SortTypeFun" />
      <SortBtn class="db-mr20" :title="'新品'" :i-sort="false" @isClick="isSortFindFun" />
      <!-- <SortBtn class="db-mr20" :title="'折扣'" :i-sort="false" /> -->
      <a-checkbox v-model:checked="hasGoods" @change="changeCheck(hasGoods)">只显示库存现货设备</a-checkbox>
    </div>

    <a-drawer
      :placement="'right'"
      :closable="true"
      :visible="visible"
      :width="528"
      @close="onClose"
    >
      <a-spin :spinning="spinning">
        <div class="attsBox">
          <div class="drawer-screen-item">
            <div class="drawer-screen-item-title" @click="showInfoFun('商品分类')">
              <span>商品分类</span>
              <img :class="showInfo === '商品分类' ? 'click-img' : ''" src="@/assets/images/icons/arrow-bottom.png" alt="arrow">
            </div>

            <div class="drawer-screen-item-info" :class="showInfo === '商品分类' ? 'show-item-info' : ''">
              <a-radio-group v-model:value="clickShopCata">
                <template v-for="(item, index) in catalog2List" :key="index">
                  <div class="raido-item" @click="clickRadioFun(item.catId, 1)">
                    <span>{{ item.name }}</span>
                    <a-radio :value="item.catId" class="radio_style" />
                  </div>
                </template>
              </a-radio-group>
            </div>
          </div>

          <div v-for="(item, index) in newAtts" :key="index" class="drawer-screen-item">
            <div class="drawer-screen-item-title" @click="showInfoFun(item.attrName)">
              <span>{{ item.attrName }}</span>
              <img :class="showInfo === item.attrName ? 'click-img' : ''" src="@/assets/images/icons/arrow-bottom.png" alt="arrow">
            </div>

            <ul class="drawer-screen-item-info" :class="showInfo === item.attrName ? 'show-item-info' : ''">
              <li v-for="(citem, cindex) in item.attrValue" :key="cindex" class="info-item-li" @click="clickRadioFun(citem)">
                <div class="radioText">
                  <span>{{ citem.title }}</span>
                </div>
                <div class="radioItem">
                  <span class="radioItemStyle" :class="citem.checked ? 'radioItemStyle-checked' : ''" />
                  <a-checkbox class="info-item-li-input" :checked="citem.checked" />
                </div>
              </li>
            </ul>
          </div>

          <div class="drawer-screen-item">
            <div class="drawer-screen-item-title" @click="showInfoFun('价格区间')">
              <span>价格区间</span>
              <img :class="showInfo === '价格区间' ? 'click-img' : ''" src="@/assets/images/icons/arrow-bottom.png" alt="arrow">
            </div>

            <div class="drawer-screen-item-info-price" :class="showInfo === '价格区间' ? 'price' : ''">
              <a-input v-model:value="minPrice" class="price-btn" placeholder="最小值" />
              <span style="margin: 0 16px;">-</span>
              <a-input v-model:value="maxPrice" class="price-btn" placeholder="最大值" />
            </div>
          </div>
        </div>

        <div class="drawer-screen-item footer-fun">
          <a-button @click="ResetFun">重置</a-button>
          <a-button @click="searchFun">查看商品</a-button>
        </div>
      </a-spin>

    </a-drawer>

  </div>
</template>

<script>
import SortBtn from '@/components/sortBtn'
export default {
  name: 'Screen',
  components: {
    SortBtn
  },
  props: {
    catalog2List: {
      type: Array,
      default: () => []
    },
    searchAtts: {
      type: Array,
      default: () => []
    }
  },
  emits: ['search', 'searchSort', 'hasStock'],
  data() {
    return {
      showInfo: '',
      visible: false,
      clickRadio: [],
      maxPrice: null,
      minPrice: null,
      hasGoods: false,
      clickShopCata: '',
      attrs: [],
      saleCount: false,
      searchSort: '',
      spinning: false
      // newAtts: []
    }
  },
  computed: {
    newAtts() {
      let list = []
      list = this.searchAtts.map(item => {
        item.attrValue = item.attrValue.map(citem => {
          return { attrId: item.attrId, title: citem, checked: false }
        })
        // console.log(item, 'computed')
        return item
      })
      return list
    }
  },
  methods: {
    showInfoFun(e) {
      this.showInfo = e === this.showInfo ? '' : e
    },
    changeCheck(e) {
      this.$emit('hasStock', e)
    },
    isSortFindFun(val) {
      if (val.title === '销量') {
        if (val.value) this.searchSort = 'saleCount_desc'
        else this.searchSort = ''
      }
      if (val.title === '价格') {
        if (val.value) this.searchSort = 'skuPrice_desc'
        else this.searchSort = ''
      }
      if (val.title === '新品') {
        if (val.value) this.searchSort = 'createTime_desc'
        else this.searchSort = ''
      }
      this.$emit('searchSort', this.searchSort)
    },
    SortTypeFun(val) {
      if (val.title === '销量') {
        if (val.sort) this.searchSort = 'saleCount_asc'
        else this.searchSort = 'saleCount_desc'
      }
      if (val.title === '价格') {
        if (val.sort) this.searchSort = 'skuPrice_asc'
        else this.searchSort = 'skuPrice_desc'
      }
      this.$emit('searchSort', this.searchSort)
    },
    clickRadioFun(val, type) {
      if (type === 1) this.clickShopCata = val
      else {
        if (this.attrs.length === 0) {
          val.checked = true
          this.attrs.push(val)
        } else {
          this.newAtts.forEach(item => {
            if (item.attrId === val.attrId) {
              item.attrValue.forEach(citem => {
                if (citem.title !== val.titel) citem.checked = false
              })
            }
          })
          this.attrs.forEach((item, index) => {
            if (item.attrId === val.attrId) {
              this.attrs.splice(index, 1)
            }
          })
          val.checked = true
          this.attrs.push(val)
        }
      }
    },
    // radioChange(e, val) {
    //   const value = e ? e.target.value : val
    //   const selectitem = this.searchAtts.filter(item => {
    //     if (item.attrValue.includes(value)) return item
    //   })

    //   if (this.attrs.length === 0) {
    //     this.attrs.push({ id: selectitem[0].attrId, value: value })
    //   } else if (this.attrs.filter(item => item.id === selectitem[0].attrId).length > 0) {
    //     this.attrs.forEach((item, index) => {
    //       if (item.id === selectitem[0].attrId) {
    //         this.attrs.splice(index, 1, { id: selectitem[0].attrId, value: value })
    //       }
    //     })
    //   } else {
    //     this.attrs.push({ id: selectitem[0].attrId, value: value })
    //   }
    // },
    ResetFun() {
      this.spinning = true
      setTimeout(() => {
        this.spinning = false
      }, 1000)
      this.clickShopCata = ''
      this.maxPrice = null
      this.minPrice = null
      this.attrs = []
      const data = {
        catalogId: '',
        attrs: [],
        skuPrice: ''
      }
      this.$emit('search', data)
    },
    showDrawer() {
      this.visible = true
    },
    onClose() {
      this.visible = false
    },
    searchFun() {
      this.spinning = true
      setTimeout(() => {
        this.spinning = false
      }, 1000)
      const list = []
      this.attrs.forEach(item => {
        list.push(item.attrId + '_' + item.title)
      })
      let price = ''
      if (this.minPrice && this.maxPrice) {
        price = this.minPrice + '_' + this.maxPrice
      }
      const data = {
        catalog3Id: this.clickShopCata,
        attrs: list,
        skuPrice: price
      }
      this.spinning = true
      setTimeout(() => {
        this.spinning = false
      }, 1000)
      this.$emit('search', data)
    }
  }
}
</script>
<style lang='less'>
.screen {
  padding: 32px 24px;

  .sort-fun {
    border-top: 1px solid #F3F3F3;
    padding-top: 24px;
  }
  .screen-list {
    display: flex;
    align-items: center;

    .screen-item {
      width: 120px;
      height: 40px;
      background: #E5E5E5;
      border-radius: 23px;
      text-align: center;
      line-height: 40px;
      margin-right: 24px;
      cursor: pointer;
    }
  }

}

.drawer-screen-item {
  width: 100%;
  padding: 32px 0;
  border-bottom: 1px solid #F3F3F3;

  &:last-child {
    border: none;
  }

  .drawer-screen-item-title {
    color: #666666;
    font-size: 14px;
    font-weight: bold;
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;

    & > img {
      width: 24px;
      height: 24px;
      cursor: pointer;
      transition: all .2s linear;
    }
    .click-img {
      transform: rotate(180deg);
    }
  }
  .drawer-screen-item-info {
    width: 100%;
    height: 0;
    overflow: hidden;
    transition: all .2s linear;
    .raido-item {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding-top: 32px;
      cursor: pointer;
    }
    .info-item-li {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding-top: 32px;
      cursor: pointer;

      .radioItem {
        position: relative;
        margin-right: 3px;
        cursor: pointer;
        .radioItemStyle {
          display: block;
          width: 16px;
          height: 16px;
          border: 1px solid #3B6652;
          border-radius: 50%;
          position: relative;

          &::after {
            content: '';
            display: block;
            width: 10px;
            height: 10px;
            border-radius: 50%;
            background-color: #fff;
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            margin: auto;
          }
        }
        .radioItemStyle-checked {
          &::after {
            background-color: #3B6652;
          }
        }
        .info-item-li-input {
          position: absolute;
          top: 0;
          bottom: 0;
          right: 0;
          left: 0;
          z-index: 1;
          opacity: 0;
          margin: auto;
          cursor: pointer;
        }
      }
    }
  }
  .drawer-screen-item-info-price {
    width: 100%;
    height: 0;
    overflow: hidden;
    display: flex;
    align-items: center;

  }
  .price {
    height: 80px;
  }
  .show-item-info {
    min-height: 225px;
  }
}
.attsBox {
  height: 700px;
  overflow-y: scroll;
  scrollbar-width: none;
  -ms-overflow-style:none;
  &::-webkit-scrollbar {
    display: none;
    width: 0px;
    height: 0px;
  }
}
.footer-fun {
  height: 104px;
  width: 100%;
  display: flex;
  justify-content: space-around;
  position: flex;
  bottom: 0;
  left: 0;

  & > button {
    width: 160px;
    height: 40px;
    background: #E5E5E5;
    border-radius: 24px;
    color: #999999;
    font-weight: 400;
    line-height: 19px;
    text-align: center;
  }
   & > button:last-child {
     background: #3B6652;
     color: #FFFFFF;
   }
}
.ant-drawer-close {
  font-size: 24px !important;
}

.ant-drawer-body {
  padding: 100px 64px 0;
  position: relative;
}
.ant-radio-wrapper {
  margin-right: 4px;
}
.ant-radio-group {
  width: 100%;
}
.price-btn {
  width: 120px;
  height: 40px;
  border-radius: 8px;
  border: 1px solid #A2A2A2;
}
</style>
