<template>
  <div class="container">
    <Breadcrumb v-if="pageType === 'hasID'" :route-list="routeInfo" />
    <TypeBanner v-if="pageType === 'hasID'" :id="catalog2Id" :title="typeBannerTitle" @selectType="selectType" />
    <!-- <GoodsList :catalog2-id="pid" :catalog3-id="cid" /> -->
    <div class="GoodsList">
      <screenShop
        :catalog2-list="catalog2List"
        :search-atts="searchAtts"
        @hasStock="hasStockFun"
        @searchSort="searchSortFun"
        @search="getDrawerInfo"
      />

      <div v-if="goodsLists.length" class="goods-box">
        <template v-for="(item, index) in goodsLists" :key="index">
          <GoodsCard class="db-mb10" :goods-info="item" />
        </template>
        <template v-for="(item, index) in 5 - (goodsLists.length % 5 === 0 ? 5 : goodsLists.length % 5)" :key="index">
          <div style="width: 244px; height: 392px;" />
        </template>
        <div
          v-if="goodsLists.length != allTotol"
          class="find-more"
          :class="isFindMore ? 'isFindMoreStyle' : ''"
          @click="clickFindMore"
        >加载更多</div>
      </div>
      <div v-if="!goodsLists.length" style="height: 400px;padding-top: 100px;">
        <a-empty :description="'暂无商品'" />
      </div>
    </div>
  </div>
</template>

<script>
import Breadcrumb from '@/components/Breadcrumb'
import TypeBanner from '@/views/shop/components/typeBanner'
// import GoodsList from '@/views/shop/components/goodsList'
import screenShop from '@/views/shop/components/screen'
import GoodsCard from '@/components/goodsCard'
import { spuInfoList, getNextCategory } from '@/api/goods'
const defaultSearchFrom = {
  catalog2Id: '',
  catalog3Id: '',
  pageNum: 1,
  attrs: [],
  sort: '',
  skuPrice: '',
  allTotol: 0,
  keyword: ''
}
export default {
  name: 'AllGoods',
  components: {
    Breadcrumb,
    TypeBanner,
    GoodsCard,
    screenShop
    // GoodsList
  },
  data() {
    return {
      catalog2Id: '', // pidl
      catalog3Id: '', // cid
      typeBannerTitle: '',
      routeInfo: [
        { path: '/shop/allgoods', title: '全部商品' },
        { path: '/shop/details', title: '商品详情' }
      ],
      isFindMore: false,
      catalog2List: [],
      goodsLists: [],
      searchAtts: [],
      searchForm: Object.assign({}, defaultSearchFrom),
      pageType: 'hasID', // hasID.hasList
      allTotol: 0
    }
  },
  watch: {
    '$route.query': {
      handler(val, oval) {
        if (this.$route.query.type === 'hasID') {
          this.pageType = 'hasID'
          this.typeBannerTitle = val.parentName
          this.catalog2Id = val.parentCid
          this.catalog3Id = val.catId
          this.searchForm.catalog3Id = val.catId
          this.routeInfo[1].title = val.parentName
          this.routeInfo[1].path = `/shop/allGoods?catId=${val.catId}`
          this.searchForm.keyword = ''
          this.getNextCategoryFun()
        } else if (this.$route.query.type === 'hasList') {
          this.pageType = 'hasList'
          this.catalog2Id = ''
          this.catalog3Id = ''
          this.searchForm.catalog3Id = ''
          this.searchForm.keyword = this.$route.query.keyword
        }
        this.searchFun()
      },
      deep: true
    }
  },
  created() {
    if (this.$route.query.type === 'hasID') {
      this.pageType = 'hasID'
      this.typeBannerTitle = this.$route.query.parentName
      this.catalog2Id = this.$route.query.parentCid
      this.catalog3Id = this.$route.query.catId
      this.searchForm.catalog3Id = this.$route.query.catId
      this.routeInfo[1].title = this.$route.query.parentName
      this.routeInfo[1].path = `/shop/allGoods?catId=${this.$route.query.catId}`
      this.searchForm.keyword = ''
      this.getNextCategoryFun()
    } else if (this.$route.query.type === 'hasList') {
      this.pageType = 'hasList'
      this.catalog2Id = ''
      this.catalog3Id = ''
      this.searchForm.catalog3Id = ''
      this.searchForm.keyword = this.$route.query.keyword
    }
    this.searchFun()
  },
  methods: {
    selectType(val) { // 点击类型轮播
      this.catalog2Id = val.parentCid
      this.catalog3Id = val.catId
      this.searchForm.catalog3Id = val.catId
      this.searchFun()
    },
    getNextCategoryFun() {
      getNextCategory(this.catalog2Id).then(res => {
        this.catalog2List = res.data
      })
    },
    hasStockFun(val) {
      if (val) this.searchForm.hasStock = 1
      else this.searchForm.hasStock = ''
      this.searchFun()
    },
    searchSortFun(val) {
      // console.log(val)
      this.searchForm.sort = val
      this.searchFun()
    },
    getDrawerInfo(val) {
      if (!val.catalog3Id && !val.skuPrice && val.attrs.length === 0) {
        this.searchForm = Object.assign({}, defaultSearchFrom)
      } else {
        if (val.catalog3Id) this.searchForm.catalog3Id = val.catalog3Id
        if (val.skuPrice) this.searchForm.skuPrice = val.skuPrice
        if (val.attrs.length) this.searchForm.attrs = val.attrs
      }
      this.searchFun()
    },
    // 查询
    searchFun() {
      // console.log(this.searchForm.catalog3Id, this.catalog3Id)
      this.searchForm.catalog2Id = this.catalog2Id
      this.searchForm.catalog3Id = this.searchForm.catalog3Id || this.catalog3Id
      if (this.searchForm.pageNum === 1) {
        this.searchAtts = []
        this.goodsLists = []
      }
      spuInfoList(this.searchForm).then(res => {
        // console.log(res)
        this.searchAtts = res.result.attrs
        res.result.products.forEach(item => {
          this.goodsLists.push(item)
        })
        this.allTotol = res.result.total
      })
    },
    // 筛选更多弹窗
    clickFindMore() {
      const _this = this
      this.isFindMore = true
      this.searchForm.pageNum++
      this.searchFun()
      setTimeout(() => {
        _this.isFindMore = false
      }, 300)
    }
  }
}
</script>
<style lang='less' scoped>
.GoodsList {
  margin-bottom: 48px;

  .goods-box {
    margin-top: 16px;
    margin-bottom: 5px;
    width: 100%;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    justify-content: space-between;
  }

  .find-more {
    width: 160px;
    height: 48px;
    border: 1px solid #A2A2A2;
    border-radius: 36px;
    color: @color;
    font-size: 14px;
    font-weight: 400;
    line-height: 48px;
    text-align: center;
    cursor: pointer;
    margin: 0 auto 40px;
    transition: all .3s linear;

    &:hover {
      border: 1px solid #666;
    }
  }
  .isFindMoreStyle {
    border: 1px solid #fff !important;
    transform: translate3d(0, -1px, 0);
  }
}
</style>
