<template>
  <div
    class="SortBtn"
    :class="[isClick ? 'clicked' : '', sort_desc_asc ? 'boxAsc' : '']"
    @click="clickFun"
  >
    <div>
      <span>{{ title }}</span>
      <span v-show="isClick && iSort" :class="sort_desc_asc ? 'triangle' : 'asc'" @click.stop="SortFun" />
    </div>

  </div>
</template>

<script>
import { ref } from 'vue'
export default {
  name: 'SortBtn',
  props: {
    title: {
      type: String,
      default: '价格'
    },
    iSort: {
      type: Boolean,
      default: false,
      required: true
    }
  },
  emits: ['isClick', 'sorType'],
  setup(props, content) {
    const isClick = ref(false)
    const sort_desc_asc = ref(false)
    const clickFun = () => {
      isClick.value = !isClick.value
      if (!isClick.value) sort_desc_asc.value = false
      // else sort_desc_asc.value = true
      const data = {
        value: isClick.value,
        title: props.title
      }
      content.emit('isClick', data)
    }
    const SortFun = () => {
      sort_desc_asc.value = !sort_desc_asc.value
      const data = {
        title: props.title,
        sort: sort_desc_asc.value
      }
      content.emit('sorType', data)
    }
    return {
      isClick,
      clickFun,
      SortFun,
      sort_desc_asc
    }
  }
}
</script>

<style lang="less">
.SortBtn {
  padding: 11px 25px;
  font-weight: 400;
  // color: #3B6652;
  border-radius: 8px;
  border: 1px solid #A2A2A2;
  color: #A2A2A2;
  transition: all .3s linear;
  cursor: pointer;
  display: inline-block;
  // min-width: 77px;
  & > div {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .triangle {
    display: block;
    position: relative;
    z-index: 1;
    border-bottom: 10px solid #fff;
    border-right: 8px solid transparent;
    border-left: 8px solid transparent;
    margin-left: 16px;
    // background: #3B6652;
  }
  .asc {
    display: block;
    position: relative;
    z-index: 1;
    border-bottom: 10px solid #3B6652;
    border-right: 8px solid transparent;
    border-left: 8px solid transparent;
    margin-left: 16px;
    transform: rotate(180deg);
    // background: #3B6652;
  }
}
.clicked {
  // background: #3B6652;
  border: 1px solid #3B6652;
  color: #3B6652;
}
.boxAsc {
  background: #3B6652;
  color: #fff;
}
</style>
